import React, { useState } from "react"
import * as Sentry from "@sentry/gatsby"
import { Link } from "gatsby"
import { MdEmail, MdError } from "react-icons/md"
import { statuses } from "../pages/auth"
import { statuses as authStatuses } from "../hooks/auth"
import { useAppState } from "../components/provider"
import firebaseApp from "../configs/firebase"
import Layout from "../components/layout"
import LoaderBlock from "../components/loader"
import SEO from "../components/seo"

export const reportToSentry = (error, context = {}) =>
  Sentry.withScope(scope => {
    scope.setTags(context.tags)
    scope.setExtras(context.extra)
    scope.setUser(context.user)
    Sentry.captureException(error)
  })

const IndexPage = () => {
  const {
    auth: { status: authStatus },
  } = useAppState()

  const [status, setStatus] = useState()
  const [email, setEmail] = useState("")

  const onEmailChange = event => {
    setEmail(event.target.value)
  }

  const onSubmit = event => {
    event.preventDefault()
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${window.location.origin}/auth`,
      // This must be true.
      handleCodeInApp: true,
    }

    setStatus(statuses.processing)
    firebaseApp
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email)
        setStatus(statuses.success)
        firebaseApp.analytics().logEvent("email_submit")
      })
      .catch(error => {
        // Some error occurred, you can inspect the code: error.code
        reportToSentry(error)
        setStatus(statuses.failed)
      })
  }

  let signInTemplate
  if (authStatus === authStatuses.loggedIn) {
    signInTemplate = (
      <Link className="Btn" to="/profile/">
        Proceed with your Stakeholder Profile
      </Link>
    )
  } else {
    switch (status) {
      case statuses.success:
        signInTemplate = (
          <section className="Panel textFormat">
            <div className="iconContainer">
              <MdEmail size="4em" className="Icon success" />
            </div>
            <p className="Text">
              We sent you an email with a magic-link to <strong>{email}</strong>
              . Please, check your inbox and follow instructions to authenticate
              to your Stakeholder Profile.
            </p>
            <p className="Text">
              <em>
                If you&apos;re not getting the email within a few minutes, then
                please, check your spam folder or refresh this page and try
                again.
              </em>
            </p>
          </section>
        )
        break
      case statuses.failed:
        signInTemplate = (
          <section className="Panel textFormat">
            <div className="iconContainer">
              <MdError size="4em" className="Icon failed" />
            </div>
            <p className="Text">
              Something went wrong and we cannot send you the email to
              authenticate you. Please, try to refresh the page and submit the
              form again. If it's still a problem we're glad to help you on the
              Intercom chat.
            </p>
          </section>
        )
        break
      default:
        signInTemplate = (
          <form onSubmit={onSubmit}>
            <div className="InlineForm">
              <div className="Textfield translucent widen">
                <label className="label" htmlFor="email">
                  Get access with an email.
                </label>
                <input
                  className="input"
                  disabled={status === statuses.processing}
                  id="email"
                  onChange={onEmailChange}
                  placeholder="Email address"
                  required
                  type="email"
                />
              </div>
              <button className="Btn primary" type="submit">
                Send
              </button>
            </div>
          </form>
        )
        break
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      <section className="Landing Section">
        <div className="Background">
          <div className="content">
            <div className="FeatureBlock">
              <div className="details Hero">
                <h1 className="title">Building future, together</h1>
                <p className="lead">
                  Mothership is building software solutions to help companies
                  and individuals adapt to emerging technologies, be it
                  blockchain or digital identity. The company has a first-hand
                  experience with building fin-tech solutions and applying the
                  best practices to comply with recent GDPR, KYC and AML/CTF
                  requirements.
                </p>
              </div>
              <div className="actions">
                <div className="MailingList justify">
                  <h1 className="title">Stakeholder Profile</h1>
                  <p className="lead">
                    We're embarking on a long journey and our goal is to build
                    and grow a business more successful than ever. We need to
                    know who is on board and establish business relationships
                    accordingly. The website will allow you to be even further
                    involved in the company once your MSP holdings are verified
                    and those who register will get tailored news and surveys to
                    guide us in shaping Mothership’s future.
                  </p>
                  {signInTemplate}
                  <LoaderBlock
                    isLoading={
                      status === statuses.processing ||
                      authStatus === authStatuses.processing
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Sentry.withErrorBoundary(IndexPage, {
  fallback: "an error has occurred in index page",
})
