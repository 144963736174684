import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import * as Sentry from "@sentry/gatsby"
import CircularProgress from "@material-ui/core/CircularProgress"
import { MdError } from "react-icons/md"
import { reportToSentry } from "."
import { statuses as authStatuses } from "../hooks/auth"
import { useAppState } from "../components/provider"
import firebaseApp from "../configs/firebase"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const statuses = {
  initial: "initial",
  processing: "processing",
  success: "success",
  failed: "failed",
}

const AuthPage = () => {
  const {
    auth: { user, status: authStatus },
  } = useAppState()
  const [status, setStatus] = useState(statuses.initial)

  useEffect(() => {
    // Confirm the link is a sign-in with email link.
    setStatus(statuses.processing)
    if (authStatus === authStatuses.loggedIn) {
      navigate("/profile")
    } else if (authStatus === authStatuses.initial && user === null) {
      if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem("emailForSignIn")
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email =
            window.prompt("Please provide your email for confirmation") || ""
        }

        // The client SDK will parse the code from the link for you.
        firebaseApp
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(_result => {
            firebaseApp.analytics().logEvent("email_confirm")
            // Clearing URL params
            window.history.replaceState(null, null, window.location.pathname)
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn")
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            navigate("/profile")
          })
          .catch(error => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            if (error.code !== "auth/invalid-action-code") {
              reportToSentry(error)
            }
            setStatus(statuses.failed)
          })
      } else {
        navigate("/")
      }
    }
  }, [user, authStatus])

  let formTemplate = null
  switch (status) {
    case statuses.success:
      formTemplate = (
        <section className="Panel textFormat">
          <p className="Text">
            <strong>Authenticated successfully!</strong> Redirecting to your
            profile&hellip; Click <Link to="/profile/">here</Link> if it
            doesn&apos;t redirect you.
          </p>
        </section>
      )
      break
    case statuses.failed:
      formTemplate = (
        <section className="Panel textFormat">
          <div className="iconContainer">
            <MdError size="4em" className="Icon failed" />
          </div>
          <p className="Text">
            We weren't able to authenticate you. It looks like the magic link
            expired. Please try to login again. If it's still a problem we're
            glad to help on the Intercom chat.
          </p>
          <div className="iconContainer">
            <Link className="Btn primary" to="/">
              Login again
            </Link>
          </div>
        </section>
      )
      break
    case statuses.processing:
    default:
      formTemplate = (
        <section className="Panel textFormat">
          <div className="iconContainer">
            <CircularProgress />
          </div>
        </section>
      )
      break
  }

  return (
    <Layout>
      <SEO title="Stakeholder Profile" />
      <div className="Section">
        <div className="PanelContainer">
          <h1 className="Title">Stakeholder Profile</h1>
          {formTemplate}
        </div>
      </div>
    </Layout>
  )
}

export default Sentry.withErrorBoundary(AuthPage, {
  fallback: "an error has occurred in auth page",
})
